// Enterによるフォーム送信を原則禁止とする
$(document).ready(function() {
  $('form input').keydown(function (e) {
    if (e.keyCode == 13) {
      // ギフトコードのテキスト入力はキーボードを閉じる
      $("#usage_status_code").blur();
      e.preventDefault();
      return false;
    }
  });
});
