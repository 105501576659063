$(document).ready(function() {
  $('#agreement_checkbox').click(function(){
    if ($(this).is(':checked')) {
      $('#agreement_button').prop("disabled", false);
      $('#agreement_button').removeClass('is-disabled');
    } else {
      $('#agreement_button').prop("disabled", true);
      $('#agreement_button').addClass('is-disabled');
    }
  });
});
