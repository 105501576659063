const disableBrowserBack = function() {
  history.pushState(null, null, null);

  $(window).on("popstate", function(){
     history.pushState(null, null, null);
  });
};

$(document).ready(function() {
  if($('.disable_browser_back').length != 0){
    disableBrowserBack();
  }
});
