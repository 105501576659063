$(() => {
  // ドロップダウンを開く時、他のドロップダウンを閉じる
  $('.c-dropdown__input').change((e) => {
    if (!e.currentTarget.checked) return;

    $('.c-dropdown__input').not(e.currentTarget).prop('checked', false);
  });

  // エリアボタンのドロップダウンを開いたときにoverflow-xとoverflow-yのスタイルを切り替える
  // overflow-x: scroll; overflow-y: visible; のように両方適用するとCSSの仕様で overflow-y: auto; になってしまうので片方だけを適用する
  $(".js-area-dropdown").change((e) => {
    if (e.currentTarget.checked) {
      $(".HomeShow-area__list").addClass("dropdown-open");
    } else {
      $(".HomeShow-area__list").removeClass("dropdown-open");
    }
  });
});
