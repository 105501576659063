$(document).ready(function() {
  function showDatetime() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const hour = ('0' + today.getHours()).slice(-2);
    const minute = ('0' + today.getMinutes()).slice(-2);
    const second = ('0' + today.getSeconds()).slice(-2);

    $('#datetime').html(`${year}年${month}月${date}日 ${hour}:${minute}:${second}`);
  }

  if($("#datetime").length != 0) {
    showDatetime();
    setInterval(showDatetime,1000);
  }
});
