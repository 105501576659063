const copyToClipboard = (copyTarget) => {
  setTimeout(async () => {
    copyTarget.select();
    document.execCommand('Copy');
    alert('コピーしました');
  }), 1000
};

$(() => {
  $('.copy-btn').on('click', (e) => {
    const targetId = $(e.target).data('target-id');
    copyToClipboard(document.getElementById(targetId));
  });
});
